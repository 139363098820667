import React, { useRef, useLayoutEffect } from 'react'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Text } from '@/components/Text'
import { Wrapper, StyledDivider } from './styled'
import { MobileFooter } from '@/layouts/styled'
import MobileBack from '@/components/MobileBack'
import gsap from 'gsap'

const PlanetPage: React.FC = () => {
  const { t } = useTranslation()
  const dividerRef = useRef(null)

  useLayoutEffect(() => {
    const tl = gsap.timeline({})
    tl.fromTo(
      dividerRef.current,
      1.5,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
        ease: 'sine.out',
      },
      0.8,
    )
  })
  return (
    <>
      <Page
        content={
          <PageWrapper>
            <Wrapper>
              <Text
                title={t('dna.join_toggle2_title', {
                  defaultValue: 'WE ARE RELIABLE',
                })}
                text={t('dna.join_toggle2_body', {
                  defaultValue:
                    'Our business is about reliability. We understand that. We understand that our clients entrust the continuation of their business to us. We feel that responsibility. That’s why we are dedicated and work hard to deliver. To make things right.',
                })}
              />
              <StyledDivider ref={dividerRef} />
            </Wrapper>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
          isSubPage: true,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          variant="light"
          delay={0}
          time={1}
          url="/our-dna/values"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default PlanetPage
